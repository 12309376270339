import { ComponentProps } from 'react'

type SimplePageProps = ComponentProps<'div'>

const SimpleLayout = ({ children }: SimplePageProps) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 block overflow-y-auto overflow-x-hidden outline-0">
      {children}
    </div>
  )
}

export default SimpleLayout
