import dynamic from 'next/dynamic'
import { ComponentProps } from 'react'

import Header from '@Components/page-header'
import Navbar from '@Components/page-navbar'

type MainPageProps = ComponentProps<'div'>

const Footer = dynamic(() => import('@Components/page-footer'), { ssr: false })

const MainLayout = ({ children }: MainPageProps) => {
  return (
    <>
      <Header />
      <div
        id="ads-midia-background"
        className="main-content ads-midia-background"
        data-testid="ads-midia-background"
      >
        {children}
        <Navbar />
        <Footer />
      </div>
    </>
  )
}

export default MainLayout
