'use client'

import { MainLayout } from 'src/layout'
import Button from 'src/ui/button'

import Link from '@Components/Link'

const NotFoundPage = () => {
  return (
    <MainLayout>
      <div className="mx-auto mb-[10px] mt-[70px] max-w-screen-sm px-[15px] py-0 text-[15px] leading-7">
        <h1 className="mb-2 font-uol-bold text-[28px] uppercase">Erro 404</h1>
        <span className="text-lg leading-none">
          Não encontramos a página que você está procurando :(
        </span>

        <figure>
          <img
            className="mx-0 my-5 max-w-full"
            src="https://ingresso-a.akamaihd.net/catalog/Content/img/error-img-03176b1a6d.jpg"
            alt="Cena do filme Pulp Fiction ou Tempo de Violência em português, em que John Travolta aparece confuso"
          />
        </figure>

        <p className="mb-4">Isso pode ter acontecido porque:</p>

        <ul className="mb-4 list-disc pl-[15px]">
          <li>Essa página não existe mais</li>
          <li>Você digitou algo errado</li>
          <li>Estamos tão confusos quanto o John Travolta</li>
        </ul>

        <p className="mb-4">Tente novamente!</p>

        <Button variant="outline" asChild>
          <Link href="/">Ir para a Home</Link>
        </Button>
      </div>
    </MainLayout>
  )
}

export default NotFoundPage
